<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->
    
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-title>
                <h3>
                  امر الصرف
                </h3>
              </b-card-title>

              <!-- Spacer -->
              <hr class="invoice-spacing">
              <b-card-body>
                <b-row>
                  <b-col
                    cols="3"
                    lg="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false : null"
                      >
                        <label class="">امر الشراء</label>
                        <v-select
                          v-model="Form.purchase_order_id"
                          :dir="
                            $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                          "
                          :options="PurchesItem"
                          label="code"
                          :clearable="false"
                          :reduce="(val) => val.id"
                          
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="3"
                    lg="3"
                  >
                  <label class=""> الكمية كتابة</label>
                    <b-form-input
                      v-model="Form.amount_in_words"

                      class="mb-2 mb-lg-0"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    lg="3"
                  >
                  <label class="">تكلفة الهرم </label>
                    <b-form-input
                      v-model="Form.haram_fees"
                      type="number"

                      class="mb-2 mb-lg-0"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    lg="3"
                  >
                  <label class=""> الرسوم المصرفية </label>
                  
                    <b-form-input
                      v-model="Form.bank_charges"
                      type="number"

                      class="mb-2 mb-lg-0"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    lg="3"
                  >
                  <label class="">  الكمية الكلية </label>
                   
                    <b-form-input
                      v-model="Form.total_amount"
                      type="number"

                      class="mb-2 mb-lg-0"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    lg="3"
                  >
                  <label class="">  رقم الحساب  </label>
                    
                    <b-form-input
                      v-model="Form.account_no"
                      type="text"

                      class="mb-2 mb-lg-0"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    lg="3"
                  >
                  <label class="">   اسم المصرف  </label>
                   
                    <b-form-input
                      v-model="Form.bank_name"
                      type="text"

                      class="mb-2 mb-lg-0"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    lg="3"
                  >
                  <label class="">    طريقة الدفع  </label>
                  
                    <b-form-input
                      v-model="Form.payment_method"
                      type="text"

                      class="mb-2 mb-lg-0"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    lg="3"
                  >
                  <label class="">     المستلم  </label>
                   
                    <b-form-input
                      v-model="Form.recipient"
                      type="text"

                      class="mb-2 mb-lg-0"
                    />
                  </b-col>
                  <b-col
                    cols="3"
                    lg="3"
                  >
                  <label class="">     التفاصيل  </label>
                    <b-form-textarea
                      v-model="Form.description"
                      type="text"

                      class="mb-2 mb-lg-0"
                    />
                  </b-col>
                </b-row>

              </b-card-body>
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->

              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">
                <h4> الحسابات الفرعية</h4>
                <div
                  ref="form"
                  class="repeater-form"
                >
                  <b-row
                    v-for="(item, index) in Form.sub_accounts"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label>
                              رقم الحساب
                            </label>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label>
                              الكمية
                            </label>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <label>
                              العملة
                            </label>
                          </b-col>
                         

                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false : null"
                              >
                                <label class="d-inline d-lg-none">sub_accounts</label>
                                <v-select
                                  v-model="item.sub_account_id"
                                  :dir="
                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                  "
                                  :options="SubAccountItem"
                                  label="name"
                                  :clearable="false"
                                  :reduce="(val) => val.id"
                                  class="mb-2 item-selector-title"
                                  placeholder="Select Item"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false : null"
                              >
                                <label class="d-inline d-lg-none">الكمية</label>
                                <b-form-input
                                  v-model="item.amount"
                                  type="number"
                                  class="mb-2"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                label-for="notes"
                                :state="errors.length > 0 ? false : null"
                              >
                                <label
                                  class="d-inline d-lg-none"
                                >العملة</label>
                                <v-select
                                  v-model="item.currency_id"
                                  :dir="
                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                  "
                                  :options="currencyOption"
                                  label="name"
                                  :clearable="false"
                                  :reduce="(val) => val.id"
                                  class="mb-2 item-selector-title"
                                
                                />

                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>

                        </b-row>
                        <div
                          class="d-flex flex-column justify-content-between border-left py-50 px-25"
                        >
                          <feather-icon
                            v-if="index != 0"
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  <span>
                    اضافة حساب اخرى
                  </span>
                </b-button>
              </b-card-body>
              <b-card-body class="invoice-padding form-item-section">
                <h4>تفاصيل الوثائق</h4>
                <div
                  ref="form"
                  class="repeater-form"
                >
                  <b-row
                    v-for="(item, index) in Form.support_documents"
                    :key="index"
              
                    class="pb-2"
                  >
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                         
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <label>
                              النص
                            </label>
                          </b-col>
                         

                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false : null"
                              >
                                <label class="d-inline d-lg-none">Document</label>
                                <b-form-input
                                  v-model="item.text"
                                  type="text"
                                  class="mb-2"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                       

                        </b-row>
                        <div
                          class="d-flex flex-column justify-content-between border-left py-50 px-25"
                        >
                          <feather-icon
                            v-if="index != 0"
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItemFormDocument(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemFormDocument"
                >
                  <span>
                    اضافة وثيقة اخرى
                  </span>
                </b-button>
              </b-card-body>
              <!-- Invoice Client & Payment Details -->

              <!-- Items Section -->

              <!-- Invoice Description: Total -->

              <!-- Spacer -->

              <!-- Note -->
              <b-col
                cols="12"
                lg="5"
              >
                <label class="d-inline d-lg-none">Notes</label>
                <b-form-textarea
                  v-model="Form.notes"
               
                  class="mb-2 mb-lg-0"
                />
              </b-col>
              <hr class="invoice-spacing">
              <b-col
                class="border-Primary mb-10"
                md="12"
                xl="4"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  type="submit"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50 mb-10"
                  />
                  <span class="align-middle"> ارسال الطلب</span>
                </b-button>
              </b-col>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
    </b-row>

    <!-- <invoice-sidebar-send-invoice />
        <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  bCardTitle,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'
import financeStoreModule from './financeStoreModule.js'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BRow,
    bCardTitle,
    financeStoreModule,
    BCardTitle,
    BCol,
    BCard,
    BCardBody,
    required,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    flatPickr,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  methods: {
    addNewItemInItemForm() {
      this.Form.sub_accounts.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem)),
      )
    },
    removeItem(index) {
      this.Form.sub_accounts.splice(index, 1)
    },
    addNewItemInItemFormDocument() {
      this.Form.support_documents.push(
        { text: '' }
      
      )
    },
    removeItemFormDocument(index) {
      this.Form.support_documents.splice(index, 1)
    },
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
    const FINANCE_APP_STORE_MODULE_NAME = 'app-finance'

    // Register module
    if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME) }
    })

    const ItemData = ref([])
    const PurchesItem = ref([])
    const SubAccountItem = ref([])
    const currencyOption = ref([])
    store.dispatch('app-finance/GetCurrency').then(response => {
      //console.log('currencyOption,', response)
      currencyOption.value = response
    })
    store.dispatch('app-finance/GetPurchesItem').then(response => {
      //console.log('response,', response)
      PurchesItem.value = response.data
    })
    store.dispatch('app-finance/GetItem').then(response => {
      //console.log(response)
      // ItemData.value = response.data
    })

    const itemFormBlankItem = {
      sub_account_id: '',
      currency_id: 1,
      amount: '',
   
    }
   
    const Form = ref({
      purchase_order_id: '',
      description: '',
      amount_in_words: '',
      haram_fees: '',
      bank_charges: '',
      total_amount: '',
      account_no: '',
      bank_name: ' ',
      payment_method: '',
      recipient: '',
      notes: '',
      support_documents: [{ text: '' }],
      sub_accounts: [{ sub_account_id: '', currency_id: 1, amount: '' }],
    })
    const getAccountId = id => {
     // console.log('accountId',PurchesItem.value)
      const accountId = PurchesItem.value.find(el => el.id == id).transaction_id.account_id
    //  console.log(accountId)
      // store.dispatch('app-finance/getSubAccountById', accountId).then(response => {
      //   //console.log('currencyOption,', response)
      //   SubAccountItem.value = response
      // })
    }
    store.dispatch('app-finance/GetSubAccounts').then(response => {
        //console.log('currencyOption,', response)
        SubAccountItem.value = response
      })
    const onSubmit = () => {
      //console.log(Form)

      store.dispatch('app-finance/NewPaymentOrder', Form.value).then(response => {
        Form.value.purchase_order_id = ''
        Form.value.description = ''
        Form.value.amount_in_words = ''
        Form.value.haram_fees = ''
        Form.value.bank_charges = ''
        Form.value.account_no = ''
        Form.value.bank_name = ''
        Form.value.payment_method = ''
        Form.value.recipient = ''
        Form.value.notes = ''
        Form.value.support_documents = [{ text: ''}]

        Form.value.sub_accounts = [{ sub_account_id: '', currency_id: 1, amount: '' }]
        resetForm()
        Vue.swal({
          title: '',
          text: 'تمت أضافة امر الصرف بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    }

    const paymentMethods = ['Bank Account', 'PayPal', 'UPI Transfer']

    return {
      SubAccountItem,
      getAccountId,
      currencyOption,
      onSubmit,
      refFormObserver,
      PurchesItem,
      resetForm,
      getValidationState,
      ItemData,
      Form,

      itemFormBlankItem,
      paymentMethods,
    }
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>

  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";

  .form-item-section {
    background-color: $product-details-bg;
  }

  label {
    color: #7e7a9f;
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .form-item-action-col {
    width: 27px;
  }
  b-card-title {
    text-align: center;
    margin-top: 12px;
  }
  .repeater-form {
    // overflow: hidden;
    transition: 0.35s height;
  }
  .border-Primary.mb-10.col-md-12.col-xl-4 {
    margin-bottom: 21px;
  }
  .v-select {
    &.item-selector-title,
    &.payment-selector {
      background-color: #fff;

      .dark-layout & {
        background-color: unset;
      }
    }
  }

  .dark-layout {
    .form-item-section {
      background-color: $theme-dark-body-bg;

      .row .border {
        background-color: $theme-dark-card-bg;
      }
    }
  }
  </style>
